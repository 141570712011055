@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
a{
  text-decoration: none;
}
body{
  font-family: 'Poppins', sans-serif !important;
  scrollbar-width: thin;
  scrollbar-color: #e56425 rgb(227, 226, 226);
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  scroll-behavior: smooth;
}

html {
    --scrollbarBG: rgb(227, 226, 226);
    --thumbBG: #e56425;
  }
  body::-webkit-scrollbar {
    width: 10px;
  }

  body::-webkit-scrollbar-track {
    background: rgb(227, 226, 226);
    background: var(--scrollbarBG);
  }
  body::-webkit-scrollbar-thumb {
    background-color: #e56425;
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid rgb(227, 226, 226);
    border: 3px solid var(--scrollbarBG);
  }

  /* ------ Form Inputs ID's ------- */
  #title,
  #via,
  #category,
  #venue,
  #campaign,
  #sponser,
  #pdf,
  #isPaid,
  #amount,
  #startDate,
  #endDate,
  #lastDate,
  #medium,
  #state, 
  #city,
  #startTime,
  #endTime,
  #min,
  #max{
    scroll-margin-top: 70px !important;
  }

  /*----- All Buttons -----*/
  .btn-logout,
  .btn-submit{
    background: linear-gradient(180deg, #e57a44, #e56425);
    padding: 4px 12px;
    border-radius: 12px;
    border: 1px solid transparent !important;
    transition: 0.2s  !important;
  }
  .btn-logout:hover{
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
    background: transparent;
    color: black;
    border: 1px solid black !important;
  }
  .btn-submit:hover{
    -webkit-transform: scale(1.09) !important;
            transform: scale(1.09) !important;
    background: linear-gradient(180deg, #e57a44, #e56425);
    /* color: black; */
    /* border: 1px solid black !important; */
  }


  .btn-previous,
  .btn-next{
    background: transparent !important;
    border: none;
    color: #e56425 !important;
    transition: 0.3s;
  }

  .btn-previous:hover{
    -webkit-transform: translateX(-5px) !important;
            transform: translateX(-5px) !important;
  }
  .btn-next:hover{
    -webkit-transform: translateX(5px) !important;
            transform: translateX(5px) !important;
  }

  .btn-cancel{
    background: transparent !important;
    color: #e56425;
    transition: 0.2s;
    border: none;
  }
  .btn-cancel:hover{
    -webkit-transform: scale(1.09);
            transform: scale(1.09);
    color: #e56425;
  }


/* --- Login CSS ----*/
.loginNew{
  height:100vh;
}
.loginNew .left{
  background: url(/static/media/yoga.1250fd43.jpg) center center no-repeat;
  background-size: cover;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.loginNew .card h1{
  background: linear-gradient(90deg, #f98f5a, #e56425);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  
}

.loginNew .right .card{
  border: 0px;
  border-radius: 15px;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  width: 75%;
}
@media(max-width:992px){
  .loginNew .right .card{
    width: 100%;
  }
}

.loginNew .right input{
  border-radius: 12px;
}

.loginNew .right span{
  border-radius: 12px;
}

nav{
  position: fixed;
  width:100%;
  background: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 20px;
  z-index: 1000;
 }

 nav h5{
  color: black;
  font-weight: 600;
 }

 nav ul{
display: flex;
list-style: none;
padding: 0px;
margin: 0px;
}

 nav ul li {
  letter-spacing: 1px;
  font-weight: 400;
  padding: 2px 10px;
  transition: 0.2s;
  display: inline-block;
  color: var(--yellow-brand-color);
}

nav ul li:hover{
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  color: white;
 }
  
@media(max-width:992px){
  
.lg-nav{
  display: none;
}

.mobile-nav {
  position: fixed;
  --gap:2rem;
  border-radius: 0px;
  inset:0 0 0 20%;
  z-index: 1000;
  flex-direction: column;
  padding: 20% 0px;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  transition: 0.3s;
list-style: none;
margin: 0;
background: #FFFFFF;
  }
  .mobile-nav li {
    margin-bottom: 15px;
    color: yellow;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .mobile-nav[data-visible="true"]{
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  
   }

 .mobile-nav-toggle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 30px;
  cursor: pointer;
  transition: all .5s ease-in-out;

  /* border: 3px solid #fff; */
}
.mobile-nav-toggle .menu-btn__burger {
  width: 35px;
  height: 3px;
  background: #e56425;
  border-radius: 5px;
  transition: all .5s ease-in-out;
  z-index: 9999;
  
}
.mobile-nav-toggle .menu-btn__burger::before,
.mobile-nav-toggle .menu-btn__burger::after {
  content: '';
  position: absolute;
  width: 36px;
  height: 3px;
  background: #e56425;
  border-radius: 5px;
  transition: all .5s ease-in-out;
 
}
.mobile-nav-toggle .menu-btn__burger::before {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}
.mobile-nav-toggle .menu-btn__burger::after {
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
}

.mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger {
  -webkit-transform: translateX(-50px);
          transform: translateX(-50px);
  background: transparent;
  box-shadow: none;
}
.mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::before {
  -webkit-transform: rotate(45deg) translate(35px, -35px);
          transform: rotate(45deg) translate(35px, -35px);
}
.mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::after {
  -webkit-transform: rotate(-45deg) translate(35px, 35px);
          transform: rotate(-45deg) translate(35px, 35px);
}
 
}


 /* ------ SideBar-lg CSS for Large or Desktop Devices ------ */
 @media(min-width:992px){

  .lg-nav{
    display: flex;
  }
  .mobile-nav{
    display: none;
  }
  .mobile-nav-toggle{
    display: none;
  }

.sidenav-lg::-webkit-scrollbar {
  width: 10px;
}
.sidenav-lg::-webkit-scrollbar-track {
  background: rgb(227, 226, 226);
  background: var(--scrollbarBG);
}
.sidenav-lg::-webkit-scrollbar-thumb {
  background-color: #e56425;
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid rgb(227, 226, 226);
  border: 3px solid var(--scrollbarBG);
}

 .sidenav-lg{
  height: 100vh;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: linear-gradient(145deg, #FFFFFF, #FFFFFF);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: scroll;
  width: 40vh;
  scrollbar-width: thin;
  scrollbar-color: #e56425 rgb(227, 226, 226);
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  scroll-behavior: smooth;
  --scrollbarBG: rgb(255, 255, 255);
  --thumbBG: #e56425;
  margin-top: 50px;
 }

 .sidenav-lg-parent{
  position: relative;
 }
 .outletDiv{
  position: absolute;
  left: 42vh;
  right: 2vh;
  top: 10vh;
 }
 }

 @media(max-width:992px){
  .outletDiv{
   position: absolute;
    left: 6vh;
    right: 6vh;
    top: 10vh;
   }
 }

 /* --- SideBar Accordion CSS for all sizes devices ----*/
 
.accordion .accrodion-body{
  background: transparent;
}
.accordion .btn-dashboard{
  background: transparent;
  color: green;
  border: none;
border-top: 1px solid green;
  outline: 0px;
  width: 100%;
  border-radius: 0px;
}
.accordion .btn-dashboard:hover{
background: green;
color: white;
}
 .accordion-item .accordion-header {
  background-color: transparent !important;
  border: none !important;
  padding: 0px;

}
.accordion .accordion-button{
  background: #e56425;
  color: white;
}
.accordion-button:focus{
  outline: none;
  box-shadow: none;
}
.accordion-item .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}


 .accordion-item .accordion-button:focus:after {
  box-shadow: none;
  outline: white;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  color: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

 .accordion-item {
  box-shadow: 0 2px 10px 0 rgba(110, 130, 208, .18);
  border: 0px;
}

 .accordion .btn-accordionMenu{
  background: transparent !important;
  border: 1px solid #e56425;
  color: #e56425;
  transition: 0.3s;
  border-radius: 12px;
}
.accordion .btn-accordionMenu:hover{
  background: #e56425 !important;
  border: 1px solid #e56425;
  color: #FFFFFF;

}




 .addEvent label{
    font-weight: 500;
 }

 .addEvent .uploadImg img{
  max-width: 100%;
  height: 100%;
 }

 .btn-camera{
  background: black;
  color: white;
  border: none;
  border-radius: 0px solid white !important;
 }

 .btn-camera:focus{
  background: transparent;
  border: 1px solid black;
  color: black;
 }


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* for ofline component */
.offStyle{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
